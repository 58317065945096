import {makeAutoObservable} from "mobx";

enum UIOption {
    FWBS = "https://fwbs.atlas.cartrust.com/index.html",
    ELEKTRA = "https://elektra.atlas.cartrust.com/index.html",
    AUTHORITY = "https://authority.atlas.cartrust.com/index.html"
}

const base = 3

class ChosenUiStoreStore {
    get flexModifier(): number {
        return this._flexModifier;
    }
    set flexModifier(value: number) {
        this._flexModifier = value;
    }

    get leftFrame(): UIOption {
        return this._leftFrame
    }

    get rightFrame(): UIOption{
        return this._rightFrame
    }
    set leftFrame(val: UIOption) {
        this._leftFrame = val
    }

    set rightFrame(value: UIOption) {
        this._rightFrame = value
    }

    get flexRight(): number {
        return base - this.flexModifier
    }

    get flexLeft(): number {
        return base + this.flexModifier
    }

    private _leftFrame: UIOption;
    private _rightFrame: UIOption;

    private _flexModifier: number = 0

    constructor() {
        makeAutoObservable(this)
        this._leftFrame = UIOption.ELEKTRA
        this._rightFrame = UIOption.FWBS
    }

    enlargeRight() {
        if (this.flexLeft === 2) {
            return
        }
        this.flexModifier = this.flexModifier -1;
    }

    enlargeLeft() {
        if (this.flexRight === 2) {
            return
        }
        this.flexModifier = this.flexModifier +1;
    }
}

const chosenUiStoreStore = new ChosenUiStoreStore()

export {
    ChosenUiStoreStore,
    chosenUiStoreStore,
    UIOption
}
