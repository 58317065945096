import React from 'react';
import 'bulma/css/bulma.css'
import './App.css';
import {ChoosableIFrame} from "./component";
import {chosenUiStoreStore} from "./store";
import {observer} from "mobx-react";

const App = observer(function() {
    return (
        <div style={{
            position: 'relative',
            height: '100vh',
            display:"flex"}}>

            <div style={{display: "flex", flex:`${chosenUiStoreStore.flexLeft}`, borderRight:"1px solid rgba(0,0,0,0.65)"}}>
                <ChoosableIFrame isLeft={true}></ChoosableIFrame>
            </div>

            <div style={{display: "flex", flex:`${chosenUiStoreStore.flexRight}`, borderLeft:"1px solid rgba(0,0,0,0.65)"}}>
                <ChoosableIFrame isLeft={false}></ChoosableIFrame>
            </div>

        </div>
    );
})

export {
    App
};
