import React, {useState} from "react";
import {observer} from "mobx-react";
import {chosenUiStoreStore} from "../store";
import {MainNavbar} from "./navbar";

//

export interface ChoosableIFrameProps {
    isLeft: boolean
}

const ChoosableIFrame = observer(function ({isLeft}: ChoosableIFrameProps) {
    const [menuActive, setMenuActive] = useState(false)

    return (
        <div style={{flex: "1", overflowY: "auto", overflowX: "hidden", display: "flex", flexDirection: "column"}}>
            <MainNavbar isLeft={isLeft}/>
            <div style={{flex: "1", position: "relative"}}>
                <iframe src={isLeft ? chosenUiStoreStore.leftFrame : chosenUiStoreStore.rightFrame}
                        style={{position: "absolute", height: "100%", width: "100%"}}/>
            </div>
        </div>
    )
})

export {
    ChoosableIFrame
}
