import React from "react";
import {observer} from "mobx-react";
import {chosenUiStoreStore, UIOption} from "../../store";

//
export interface MainNavbarProps {
    isLeft: boolean
}

const MainNavbar = observer(function ({isLeft}: MainNavbarProps) {
    function setTargetFrame(option: UIOption) {
        if (isLeft) {
            chosenUiStoreStore.leftFrame = option
        } else {
            chosenUiStoreStore.rightFrame = option
        }
    }

    return (
        <nav className="has-background-grey-light" style={{
            display: "flex",
            flexDirection: isLeft ? "row" : "row-reverse"
        }} role="navigation" aria-label="main navigation">
            <div style={{
                flex: "1",
                display: "flex",
                justifyContent: "flex-end"
            }}>
                <button
                    style={{
                        margin: "0.3em 0.5em",
                    }}
                    onClick={(event) => {
                        setTargetFrame(UIOption.AUTHORITY)
                    }}
                    className={"button is-primary is-small"}>
                    AUTHORITY
                </button>
                <button
                    style={{
                        margin: "0.3em 0.5em",
                    }}
                    onClick={(event) => {
                        setTargetFrame(UIOption.FWBS)
                    }}
                    className={"button is-primary is-small"}>
                    FWBS
                </button>
                <button
                    style={{
                        margin: "0.3em 0.5em",
                    }}
                    onClick={(event) => {
                        setTargetFrame(UIOption.ELEKTRA)
                    }}
                    className={"button is-primary is-small"}>
                    ELEKTRA
                </button>
            </div>
            <div
                onClick={(e) => {
                    isLeft ? chosenUiStoreStore.enlargeRight() : chosenUiStoreStore.enlargeLeft()
                }}
                className="material-symbols-outlined is-link"
                style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    background: "#3e8ed0",
                    overflow: "hidden",
                    margin: isLeft ? "0 0 0 10px" : "0 10px 0 0",
                    cursor: "pointer",
                    color: "white"
                }}>
                {isLeft ? 'chevron_left' : 'chevron_right'}
            </div>
        </nav>
    )
})

export {
    MainNavbar
}
